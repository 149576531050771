<template>
  <v-card>
    <v-card-title>
      {{ $t('selectLanguage') }}
    </v-card-title>
    <v-card-text>
      <v-autocomplete
        :items="languages"
        v-model="selectedLanguage"
        hide-details
        outlined
        item-value="code"
        item-text="name"
        :filter="filter"
        :placeholder="$t('pleaseSelectALanguage')"
        :no-data-text="$t('noLanguagesAvailable')"
      >
        <template v-slot:item="{ item }">
          <div class="pb-1">
            <div style="font-weight: 500;">
              {{ item.name }}
            </div>
            <div class="text--secondary">
              {{ item.native }}
            </div>
            <div class="text--secondary text-uppercase">
              {{ item.code }}
            </div>
          </div>
        </template>
      </v-autocomplete>
    </v-card-text>
    <v-card-actions v-if="showButtons">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row dense>
          <v-col
            cols="auto"
            class="grow"
          >
            <v-btn
              color="error"
              block
              text
              @click="close"
            >
              {{ $t('close') }}
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="grow"
          >
            <v-btn
              color="primary"
              block
              @click="select"
              :disabled="selectedLanguage === null || selectedLanguage === undefined"
            >
              {{ $t('select') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "LanguageSelection",
  props: {
    showButtons: {
      type: Boolean
    },
    selectedLanguages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedLanguage: null
    }
  },
  computed: {
    languages() {
      let languages = [
        {
          name: 'Icelandic',
          native: 'Íslenska',
          code: 'is',
          flag: ''
        },
        {
          name: 'English',
          native: 'English',
          code: 'en',
          flag: ''
        }
      ]

      if(Array.isArray(this.selectedLanguages) && this.selectedLanguages.length > 0) {
        languages = languages.filter(l => !this.selectedLanguages.includes(l.code))
      }

      return languages
    }
  },
  methods: {
    close() {
      this.$emit('closed')
    },
    select() {
      this.$emit('select', this.selectedLanguage)
    },
    filter(item, query) {
      const itemName = item.name.toLowerCase()
      const itemNative = item.native.toLowerCase()
      const itemCode = item.code.toLowerCase()

      const searchText = query.toLowerCase()

      return itemName.indexOf(searchText) > -1 || itemNative.indexOf(searchText) > -1 || itemCode.indexOf(searchText) > -1
    }
  },
  watch: {
    selectedLanguage(value) {
      this.$emit('selectedLanguage', value)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-dialog
    :value="value"
    v-if="value"
    scrollable
    @click:outside="close"
    :persistent="saving"
    max-width="800"
  >
    <v-card>
      <v-card-title>
        {{ $t('translations') }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('translationOfTextsFor') }} {{ translateItemTitle }}
      </v-card-subtitle>
      <v-divider />
      <v-card-text>
        <v-container
          fluid
          class="pa-0"
        >
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-container
                  fluid
                  class="py-0"
                >
                  <v-row>
                    <v-col
                      class="text-uppercase primary white--text py-1"
                      style="border-top-left-radius: 4px; border-top-right-radius: 4px; font-weight: 500;"
                    >
                      {{ $t('defaultValues') }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="grow py-0">
                      <template v-for="key in Object.keys(translateItem)">
                        <v-row
                          :key="key"
                          v-if="whitelistedObjectKeys.includes(key) && typeof translateItem[key] === 'string'"
                          align="center"
                          dense
                        >
                          <v-col
                            cols="12"
                            md="3"
                            class="text-capitalize"
                          >
                            {{ key }}
                          </v-col>
                          <v-col
                            cols="12"
                            md="9"
                            class="text--secondary"
                          >
                            {{ translateItem[key] }}
                          </v-col>
                        </v-row>
                        <div
                          :key="key"
                          v-if="whitelistedObjectKeys.includes(key) && typeof translateItem[key] === 'object'"
                        >
                          <template v-for="subKey in Object.keys(translateItem[key])">
                            <v-row
                              :key="key + '-' + subKey"
                              v-if="whitelistedObjectKeys.includes(key + '.' + subKey)"
                              align="center"
                              dense
                            >
                              <v-col
                                cols="12"
                                md="3"
                                class="text-capitalize"
                              >
                                {{ subKey === 'value' ? key : subKey }}
                              </v-col>
                              <v-col
                                cols="12"
                                md="9"
                                class="text--secondary"
                              >
                                <span
                                  v-if="translateItem[key][subKey]"
                                  v-html="translateItem[key][subKey]"
                                />
                                <span
                                  class="font-italic"
                                  v-else
                                >{{ $t('noText') }}</span>
                              </v-col>
                            </v-row>
                          </template>
                        </div>
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="translations.length < 1">
            <v-col cols="12">
              <div class="text--secondary">
                {{ $t('noTranslatedContentFound') }}
              </div>
            </v-col>
          </v-row>
          <v-row v-else>
            <template v-for="language in translatedLanguages">
              <v-col
                cols="12"
                :key="language"
              >
                <TranslatedContent
                  :translation-language="language"
                  :translated-content="translationsInLanguage(language)"
                  @removeTranslations="removeTranslations"
                />
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                block
                @click="addNewTranslation"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>{{ $t('addTranslation') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-container
          fluid
          class="pa-0"
        >
          <v-row
            align="center"
            dense
          >
            <v-col
              cols="auto"
              class="grow"
            >
              <v-btn
                text
                color="error"
                block
                :disabled="saving"
                @click="close"
              >
                {{ $t('close') }}
              </v-btn>
            </v-col>
            <v-col
              cols="auto"
              class="grow"
            >
              <v-btn
                color="primary"
                block
                :disabled="saving"
                :loading="saving"
                @click="save"
              >
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
      <v-dialog
        v-model="showLanguageSelection"
        v-if="showLanguageSelection"
        max-width="500"
      >
        <LanguageSelection
          show-buttons
          @closed="showLanguageSelection = false"
          @select="newTranslation"
          :selected-languages="translatedLanguages"
        />
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import TranslatedContent from "@/components/translation/TranslatedContent"
import LanguageSelection from "@/components/translation/LanguageSelection"

export default {
  name: "TranslationDialog",
  components: {
    LanguageSelection,
    TranslatedContent
  },
  props: {
    value: {
      type: Boolean,
    },
    translateItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      translations: [],
      showLanguageSelection: false,
      whitelistedObjectKeys: [
        'title', 'name', 'label', 'description', 'description.value', 'description.summary'
      ]
    }
  },
  mounted() {
    if(this.itemTranslatedContent.length > 0) {
      this.translations = this.itemTranslatedContent.slice()
    }
  },
  methods: {
    close() {
      this.$emit('closed')
    },
    save() {
      this.$emit('save', this.translations)
    },
    addNewTranslation() {
      this.showLanguageSelection = !this.showLanguageSelection
    },
    newTranslation(language) {
      for(let i = 0; i < this.strings.length; i++) {
        const key = this.strings[i]
        const translationObject = {
          language: language,
          key: key,
          value: ''
        }
        this.translations.push(translationObject)
      }
      this.showLanguageSelection = false
    },
    translationsInLanguage(language) {
      return this.translations.filter(item => item !== null && item !== undefined && item.language === language)
    },
    removeTranslations(language) {
      //this.translations = this.translations.filter(tr => tr.language !== language)
      for(let i = 0; i < this.translations.length; i++) {
        let trc = this.translations[i]
        if(trc.language === language) {
          trc.value = ''
        }
      }
    }
  },
  computed: {
    strings() {
      let strings = []
      for(let i in this.translateItem) {
        if(this.whitelistedObjectKeys.includes(i)) {
          if(typeof this.translateItem[i] === 'string') {
            strings.push(i)
          }
          if(typeof this.translateItem[i] === 'object') {
            const keys = Object.keys(this.translateItem[i])
            for(let j = 0; j < keys.length; j++) {
              const key = keys[j]
              if(this.whitelistedObjectKeys.includes(i+'.'+key)) {
                if(key === 'value') {
                  strings.push(i)
                } else {
                  strings.push(key)
                }
              }
            }
          }
        }
      }
      return strings
    },
    saving() {
      return this.$store.getters.updatingItem
    },
    translatedLanguages() {
      let languages = []
      if(this.translations.length > 0) {
        for(let i = 0; i < this.translations.length; i++) {
          const translation = this.translations[i]
          if(translation === null || translation === undefined) {
            continue
          }
          if(languages.includes(translation.language)) {
            continue
          }
          languages.push(translation.language)
        }
      }
      return languages
    },
    translateItemTitle() {
      if(this.translateItem !== null && this.translateItem !== undefined) {
        if(this.translateItem.title !== undefined) {
          return this.translateItem.title
        }
        if(this.translateItem.label !== undefined) {
          return this.translateItem.label
        }
        if(this.translateItem.name !== undefined) {
          return this.translateItem.name
        }
      }
      return this.$t('selection')
    },
    itemTranslatedContent() {
      if(this.translateItem !== null && this.translateItem !== undefined) {
        if(Array.isArray(this.translateItem.translatedContent)) {
          return this.translateItem.translatedContent
        }
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>
